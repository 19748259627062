@font-face {
    font-family: Hero;
    src: url("/fonts/Hero.otf") format("opentype");
}

body {
    font-family: $standardfont;
}

.counter{
    font-family: monospace;   
}

.title {
    text-align: center;
    font-weight: 300;
    letter-spacing: 2px;
}

h1 {
    font-weight: 500;
    color: #646464;
    font-size: 2.2rem;
}

h2 {
    font-weight: 500;
    color: #646464;
    font-size: 2.4rem;
    letter-spacing: 1.5px;
}

h3 {
    font-weight: 500;
    color: #646464;
    font-size: 2rem;
}

h4 {
    font-weight: 500;
    color: #646464;
    font-size: 1.5rem;
}

.smallcounter .counter {
    font-size: 3rem;
}

.triplecounter .counter {
    font-size: 2rem;
}

.triplecounter {
    text-align: center;
}

.headline {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    color: #646464;
    font-size: 1.6rem;
    letter-spacing: 2px;
    word-spacing: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 37px;
}

.text {
    color: #646464;
    font-size: 1.2rem;
    line-height: 38px;
    letter-spacing: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.counter {
    font-size: 3rem;
}

.sidenote {
    color: #646464;
}

.legend>li {
    font-size: 1.5rem;
}

#fulllist>li {
    line-height: 52px;
}

#fulllist>li>.counter {
    font-size: 2rem;
}

footer {
    font-size: 2rem;
    letter-spacing: 3px;
    color: white;
}

#fulllist>li>.counter{
    font-size: 1.4rem;
    text-align: right;
}
.piechartdouble .headline{
    font-size: 1rem;
    text-align: center;
}

.piechartdouble .legend li{
    font-size: 1rem;
}    

@media screen and (min-width: $breakpoint-tablet) {
    #fulllist>li>.counter{
        font-size: 2rem;
    }

    .counter{
        font-size: 4rem;
    }
    #fulllist>li>.counter {
        font-size: 3.2rem;
    }
    .piechartdouble .headline{
        font-size: 1.3rem;
    }
}

@media screen and (min-width: $breakpoint-laptop) {
    .legend>li {
        font-size: 2rem;
    }
    .counter{
        font-size: 6rem;
    }
    .smallcounter .counter {
        font-size: 4rem;
    }
}