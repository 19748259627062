@import 'reset'; // Comment to not reset css
@import 'variables';
@import 'mixins';
@import 'typography';
@import 'buttons';
@import 'animations';
@import 'charts';
@import 'responsive';


body {
    margin: 0;
}

header {
    background-color: #DD2526;
    height: 76px;
}

header img {
    margin: auto;
    display: block;
    width: 50%;
    max-width: 300px;
}

#main {
    padding-left: 2em;
    padding-right: 2em;
}

.container {
    position: relative;
    padding: 20px 0px 20px 0px;
}

.grid {
    display: grid;
}

.bigcounter {
    margin-bottom: 30px;
}

.smallcounter {
    grid-template-columns: 3fr 5fr;
    .counter {
        text-align: right;
    }
}

.triplecounter {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.beforelist {
    margin-top: 40px;
    margin-bottom: 0px
}

.countercontainer span {
    position: relative;
}

.livenow {
    position: absolute;
    right: 0%;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    border-color: red;
    color: red;
    font-size: 16px;
    margin: 10px 0px 10px 0px;
    padding: 2px 4px 2px 4px;
    max-width: 80px;
}

footer {
    background-color: #AFDB45;
    text-align: center;
    height: 56px;
    line-height: 56px;
    margin: 10px 0px 10px 0px;
}