.linechart {
    max-width: 200px;
    max-height: 200px;
}

#piechart {
    max-width: 200px;
    max-height: 200px;
}

.aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.linechartcontainer {
    grid-template-columns: 1fr 1fr;
}

.bigpiechart {
    grid-template-columns: 3fr 5fr;
    grid-gap: 20px;
}


#fulllist {
    margin-top: 40px;
}

#fulllist>li {
    font-size: 24px;
    color: grey;
    display: grid;
    grid-template-columns: 1fr;
    border-top: 1px dashed grey;
}

#fulllist>li:last-child {
    border-bottom: 1px dashed grey;
}

/*
.linechartlegend {
    margin: 0px 0px 0px 20px;
    border-radius: 5px;
    border: solid 2px black;
}
*/

/*Small Piecharts*/
.piechartdouble{
    grid-template-columns: 1fr 1fr;
}

.piechartdouble .piechartlegend>ul>li:nth-child(1)::before {
    background-color: #dd2526;
}

/* Bolletjes voor legenda van Big_Piechart */

.piechartlegend>ul>li::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 10px;
    content: '';
}

.piechartlegend>ul>li:nth-child(1)::before {
    background-color: rgb(255, 99, 132);
}

.piechartlegend>ul>li:nth-child(2)::before {
    background-color: rgb(54, 162, 235);
}

.piechartlegend>.legend>li:nth-child(3)::before {
    background-color: rgb(255, 205, 86);
}

.piechartlegend > .legend > li:nth-child(4)::before {
    background-color: rgb(86, 255, 86);
}

/* Bolletjes voor legenda van linecharts */

.linechartlegend>ul>li::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    content: '';
}

#linechart1legend>ul>li:nth-child(1)::before {
    background-color: rgb(54, 162, 235);
}

#linechart1legend>ul>li:nth-child(2)::before {
    background-color: rgb(255, 99, 132);
}

#linechart2legend>ul>li:nth-child(1)::before {
    background-color: rgb(54, 162, 235);
}

#linechart2legend>ul>li:nth-child(2)::before {
    background-color: rgb(255, 99, 132);
}

.linechartlegend>ul>li {
    height: 50%;
    margin-left: 15px;
}

.linechartlegend>ul>li:nth-child(1) {
    margin-bottom: 5px;
    //border-bottom: 1px dashed grey;
}

@media screen and (min-width: $breakpoint-mobile) {
    .linechart {
        max-width: 350px;
        max-height: 350px;
    }
    #smallpiechart0,#smallpiechart1 {
        width: 200px;
        height: 200px;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    #fulllist>li{
        grid-template-columns: 1fr 1fr;
    }
    .linechart {
        max-width: 350px;
        max-height: 350px;
    }
    #smallpiechart0,#smallpiechart1 {
        width: 300px;
        height: 300px;
    }
}

@media screen and (min-width: $breakpoint-laptop) {
    .linechart {
        max-width: 350px;
        max-height: 350px;
    }
    #smallpiechart0,#smallpiechart1 {
        width: 320px;
        height: 320px;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    #piechart {
        max-width: 320px;
        max-height: 320px;
    }
    .linechart {
        max-width: 500px;
        max-height: 500px;
    }
    #smallpiechart0,#smallpiechart1 {
        width: 320px;
        height: 320px;
    }
}